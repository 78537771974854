import React from "react";
import moment from "moment";

export default class SnapshotPage extends React.Component {
  constructor() {
    super();
    this.state = {
      startingDate: "2020-09-17",
      snapshotDates: [],
    };
  }
  componentWillMount() {
    const day = new Date();
    const prevDay = day.setDate(day.getDate() - 1);
    // const nextDay = day.setDate(day.getDate() + 1);
    // const dateFormatted = this.formatDate(prevDay);
    const initialDateUnformatted = new Date(this.state.startingDate);
    const snapshotDates = this.getDates(initialDateUnformatted, prevDay);
    if (!snapshotDates.find((item) => item === this.state.startingDate)) {
      snapshotDates.unshift(this.state.startingDate);
    }
    this.setState({ snapshotDates: snapshotDates });
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  renderSnapshots(array) {
    let toReturn = [];
    for (let i = 0; i < array.length; i++) {
      const link = `https://eosasia-snapshot.s3-us-west-2.amazonaws.com/wax-mainnet/snapshot-${array[i]}.tar.gz`;
      toReturn.push(
        <li>
          <a href={link}>{array[i]}</a>
        </li>
      );
    }
    return toReturn;
  }

  render() {
    const { snapshotDates } = this.state;
    console.log("snap ", snapshotDates);

    return (
      <div style={{ margin: 20 }}>
        <h4>WAX Snapshots</h4>
        <br />
        {this.renderSnapshots(snapshotDates)}
      </div>
    );
  }
}
